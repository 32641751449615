import React from "react";
import styled from "styled-components";

import {LoadingOutlined} from "@ant-design/icons";

export default ({
  message,
  overlay,
  size = 20,
}) => (
  <Overlay overlay={overlay}>
    <div className="flex-h flex-center p15">
      <div><LoadingOutlined style={{fontSize: size, color: "white"}} /></div>
      {message && <div className="ml5">{message}</div>}
    </div>
  </Overlay>
);

const Overlay = styled.div`
  ${props => props.overlay && `
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
  `}
`;

// @flow

import React from "react";
import {Table} from "antd";
import styled from "styled-components";

import {msg} from "../messages";
import Text from "../components/Text";
import Loading from "../components/Loading";
import {dimensionMap} from "../lib/helpers";
import Pagination, {paginationStyle} from "../components/Pagination";

const breakpoint = "lg";

export default class TableHorizontal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      mobile: this.mql.matches,
    };
  }

  componentDidMount() {
    this.mql.addListener(({matches}) => {
      this.setState({
        mobile: matches,
      });
    });
  }

  onMobileChange = (current) => {
    const {
      onChange,
      pagination,
    } = this.props;
    onChange({
      ...pagination,
      current,
    });
  }
  mql = window.matchMedia(`(max-width: ${dimensionMap[breakpoint]})`)

  render() {
    const {
      dataSource,
      hasImg,
      emptyComponent,
      loading,
      ...props
    } = this.props;
    if (loading && !dataSource.length) {
      return <Loading />;
    }
    const {mobile} = this.state;
    if (!dataSource || !dataSource.length) {
      return (
        <Empty>
          {emptyComponent || <div className="p10 text-center">{msg("no_data")}</div>}
        </Empty>
      );
    }
    if (mobile) {
      if (hasImg) {
        return (
          <div>
            {dataSource.map(d => (
              <div className="flex-h">
                <div>
                  <img src={d[hasImg.key][hasImg.srcKey]} alt="" />
                </div>
                <div className="flex-1">
                  <div>{d[hasImg.title]}</div>
                </div>
              </div>
            ))}
          </div>
        );
      }
      return (
        <MobileTable className={props.className}>
          {dataSource.map(d => (
            <MobileRow>
              {props.columns.map(column => (
                <div className="flex-h">
                  {/*<Info as="div" bold size="small" className="flex-2 flex-align-self-center text-right mr10">{column.titla && column.title.toUpperCase()}</Info>*/}
                  <Text as="div" className="flex-3 flex-align-self-center">{column.render ? column.render(d[column.dataIndex], d) : d[column.dataIndex]}</Text>
                </div>
              ))}
            </MobileRow>

          ))}
          {props.pagination && (
            <Pagination
              {...props.pagination}
              onChange={this.onMobileChange}
            />
          )}
          {props.footer && <div className="mobile-table-footer">{props.footer()}</div>}
        </MobileTable>
      );
    }
    return (
      <StyledTable {...this.props} />
    );
  }
}

const MobileTable = styled.div`
  .mobile-table-footer {
    display: flex;
    justify-content: center;
    padding: 16px 24px !important;
    background: transparent;
    font-family: ${props => props.theme.fontFamily};
    font-size: 11px;
    font-weight: 600;
    text-align: center;
    color: rgba(0, 74, 179, 0.8);
  }
`;

const MobileRow = styled.div`
  padding: 8px;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.08);
  &:nth-child(odd) {
    background: rgba(153, 169, 191, 0.1);
  }
  
`;


const StyledTable = styled(Table)`
  .ant-table {
    background: transparent !important;
    table {
      border-collapse: collapse !important; 
    }
  }

  ${paginationStyle}
  .ant-table-fixed-columns-in-body {
    z-index: -1;
    visibility: visible !important;
    span {
      visibility: hidden;
    }
  }
  .ant-table-header, .ant-table-fixed-left table, .ant-table-fixed-right table {
    background: transparent !important;
  }
  th {
    padding: 5px !important;
    color: #E7E7E7 !important;
    font-weight: 600 !important;
    background: transparent !important;
    border-bottom: 2px solid #2E2E2E !important;
    font-size: 11px;
    text-transform: uppercase;
    &:first-child {
      padding-left: 24px !important;
    }
    &:last-child {
      padding-right: 24px !important;
    }
  }
  td {
    word-break: break-word;
    padding: 10px 5px !important;
    font-size: 13px;
    color: #000 !important;
    font-family: ${props => props.theme.fontFamily};
    border: 0 !important;
    &:first-child {
      padding-left: 24px !important;
    }
    &:last-child {
      padding-right: 24px !important;
    }
  }
  .ant-table-tbody > tr:hover > td {
    background: unset;
  }

  .ant-table-row {
    transition: none;
    border-bottom: 1px solid #2E2E2E !important;
    &:nth-child(even) {
      background: transparent;
      box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.05);
    }
    &:nth-child(odd) {
      background: transparent;
      box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.05);
    }
    &:last-child {
      td {
        //border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;
        box-shadow: none !important;
      }
    }
    &:hover {
      &:nth-child(even) {
        background: #1d2330;
      }
      &:nth-child(odd) {
        background: #1d2330;
      }

    }
  }
  

  .ant-table-footer {
    padding: 16px 24px !important;
    background: transparent;
    font-family: ${props => props.theme.fontFamily};
    font-size: 11px;
    font-weight: 600;
    color: rgba(0, 74, 179, 0.8);
  }
  .ant-table-pagination {
    ${paginationStyle}
  }
`;

const Empty = styled.div`
`;

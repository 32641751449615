// @flow
import React from 'react';
import styled from "styled-components";
import {LoadingOutlined} from "@ant-design/icons";

//import {ReactComponent as Metamask} from "../assets/icons/metamask.svg";
//import {ReactComponent as WalletConnect} from "../assets/icons/walletconnect.svg";
//import {ReactComponent as PayPal} from "../assets/icons/paypal.svg";

const Button = ({
  className,
  htmlType,
  children,
  icon,
  loading,
  ...props
}: Object) => (
  <StyledButton
    {...props}
    loading={loading}
    type={htmlType}
    className={className}
  >
    <div className="flex-h flex-center">
      {!!loading && (<div className="flex-v mr10"><LoadingOutlined /></div>)}
      {!!icon && (<div className="flex-v mr5">{icon}</div>)}
      <div>{children}</div>
    </div>


  </StyledButton>
);

const StyledButton = styled.button`
  white-space: nowrap;
  border-width: 1px;
  outline: none;
  display: inline-block;
  ${props => props.width && `width: ${props => ((props.width && String(props.width).indexOf("%") !== -1) ? props.width : `${props.width}px`)} !important;`}

  font-weight: 600;
  min-height: 38px;
  line-height: 1;
  border-radius: 3px;
  border: 0; //solid 1px rgba(255, 255, 255, 0.4);
  font-size: 15px;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
  background-image: linear-gradient(#28a5e0,#358ed7);
  color: #fff !important;
  border: 1px solid #358ed7;

  &a {
    color: white !important;
  }

  &:disabled {
    opacity: 0.5;
    &:hover {
      opacity: 0.5;
    }
  }

  padding: 5px 15px;
  ${props => ({
    unique: `
      background: #e6211a;
      text-transform: none;
      height: 24px;
      font-size: 14px !important;
      padding: 0 20px;
      line-height: 1.2;
      font-weight: normal;
      border-radius: 12px;
    `,
    xxs: `
      padding: 3px 5px;
      font-size: 10px;
      width: auto;
      margin: 0;
      height: auto;
      line-height: 1;
      min-height: 18px;

    `,
    xs: `
      padding: 3px 10px;
      font-size: 10px;
      width: auto;
      margin: 0;
      height: auto;
      line-height: auto;
    `,
    s: `
      padding: 5px 10px;
      font-size: 11px;
      width: auto;
      margin: 0;
      min-height: 30px;
      line-height: 1;
    `,
    m: `
      min-height: 30px;
      line-height: 1;
      border-radius: 2px;
      font-size: 13px;
      font-weight: 600;
    `,
    input: `
      padding: 12px 12px;
      width: auto;
    `,
    xlarge: `
      height: 30px;
      line-height: 30px;
      font-size: 18px;
      font-weight: 600;
    `,
    xxl: `
      height: 38px;
      line-height: 38px;
      font-size: 15px;
      font-weight: 600;
    `,

    default: "",
  })[props.size || "default"]}

  ${props => ({
    circle: `
      width: 35px;
      padding: 0;
      font-size: 16px;
      border-radius: 50%;
      height: 35px;
    `,
    default: "",
  })[props.shape || "default"]}

  ${props => ({
    transparent: `
      border: 0;
      background: none;
      padding: 0;
      min-height: auto;
    `,
    sell: `
      border: solid 1px rgba(255, 255, 255, 0.5);
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0)), linear-gradient(to bottom, #d64b62, #d64b62);
    `,
    buy: `
      border: solid 1px rgba(255, 255, 255, 0.5) !important;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0)), linear-gradient(to bottom, #7db347, #7db347);
    `,
    primary: `
      color: white;
      background-color: #358ed7;
    `,
    warning: `
      color: white;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0)), linear-gradient(to bottom, #bf7f26, #bf7f26);
    `,
    error: `
      color: white;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0)), linear-gradient(to bottom, #d01e02, #d01e02);      
      border-color: #d01e02;
    `,
    success: `
      color: white;
      background-color: #34942c;
    `,
    withdraw: `
      color: white;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0)), linear-gradient(to bottom, #d64b62, #d64b62);
    `,
    deposit: `
      color: white;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0)), linear-gradient(to bottom, #7db347, #7db347);
    `,
    gray: `
      border: solid 1px #BBB;
      color: #333 !important;
      font-weight: bold;
      background-image: linear-gradient(#fff,#ddd);
    `,
    default: `
      ${props.background && `background: ${props.background};`}
    `,
  })[props.background || "default"]}

  &:active {
    opacity: 1 !important;
    transform: scale(.98);
  }

  &:hover, &:focus {
    opacity: 0.9;
  }

  &:disabled {
    color: #bebebe;
  }
  @keyframes rainbow {
    0% { background-position: 0% 50% }
    50% { background-position: 100% 50% }
    100% { background-position: 0% 50% }
  }
`;

export const MetamaskButton = ({
  children,
  ...props
}) => (
  <Button
    {...props}
    background="gray"
  >
    <div className="flex-h flex-center">
      <div className="flex-v">
        <Metamask
          width={30}
          height={30}
        />
      </div>
      <div className="ml5">{children}</div>
    </div>
  </Button>
);

export const WalletConnectButton = ({
  children,
  ...props
}) => (
  <Button
    {...props}
    background="gray"
    icon={(<WalletConnect width={30} height={30} />)}
  >
    {children}
  </Button>
);

export const PayPalButton = ({
  children,
  ...props
}) => (
  <Button
    {...props}
    background="gray"
    icon={(<PayPal width={30} height={30} />)}
  >
    {children}
  </Button>
);


export default Button;

/*  ${props => `
    ${props.loading ? `
        background-image: linear-gradient(238deg, #fd8800, #fd008f, #9700fd, #003dfd, #05c7e6, #4bd58d) !important;
        background-size: 1000% 1000%;
        animation: rainbow 1s ease infinite;
    ` : ""}
  `}
  */


export const Button2 = ({
  className,
  htmlType,
  children,
  icon,
  loading,
  ...props
}: Object) => (
  <StyledButton2
    {...props}
    loading={loading}
    type={htmlType}
    className={className}
  >
    <div className="flex-h flex-center">
      {!!loading && (<div className="flex-v mr10"><LoadingOutlined /></div>)}
      {!!icon && (<div className="flex-v mr5">{icon}</div>)}
      <div>{children}</div>
    </div>
  </StyledButton2>
);

const StyledButton2 = styled.button`
  display: inline-block;
  border: 0;
  font-style: normal;
  font-weight: ${props => (props.bold ? "600" : "normal")};
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  border: 1px solid;
  border-radius: 5px;
  &:hover {
    color: white;
    opacity: 0.8;
  }

  &:active {
    opacity: 1;
  }

  ${props => props.width && `width: ${(props) => {
    if (String(props.width).indexOf("%") !== -1) {
      return props.width;
    }
    if (String(props.width).indexOf("px") !== -1) {
      return `${props.width}px !important;`;
    }
    return props.width;
  }}`}

  ${props => ({
    m: `
      font-size: 16px;
      line-height: 24px;
      padding: 6px 16px;
    `,
    s: `
      font-size: 14px;
      line-height: 22px;
      padding: 4px 9px;
    `,
    top: `
      font-size: 14px;
      line-height: 22px;
      padding: 8px 15px;
    `,
    default: `
      padding: 7px 16px;
      font-size: 16px;
      line-height: 24px !important;
    `,
  })[props.size || "default"]}

  ${props => `
    color: white;
    ${({
    primary: `
      background: #30318C;
      border-color: #30318C;
    `,
    success: `
      background: #009F4F;
      border-color: #009F4F;
    `,
    error: `
      background: #FF4D4F;
      border-color: #FF4D4F;
    `,
    warning: `
      background: #FFC700;
      border-color: #FFC700;
    `,
    gray: `
      background: #F5F5F5;
      border-color: white;
      color: #373737 !important;
    `,
    default: `
      background: white;
      color: #30318C !important;
    `,
  })[props.background || "default"]}`}

  ${props => ({
    white: `
      border-color: white;
      color: white !important;
      background: transparent;
    `,
    primary: `
      border-color: #30318C;
      color: #30318C !important;
      background: transparent;
    `,
    success: `
      border-width: 1px;
      border-color: #009F4F;
      color: #009F4F !important;
      background: transparent;
    `,
    error: `
      border-color: #FF4D4F;
      color: #FF4D4F !important;
      background: transparent;
    `,
    warning: `
      border-color: #FFC700;
      color: #FFC700 !important;
      background: transparent;
    `,
    black: `
      border-color: #373737;
      color: #373737 !important;
      background: transparent;
    `,

    default: `
    `,
  })[props.border || "default"]}
`;




import React from "react";
import {Redirect, Link} from "react-router-dom";
import { connect } from 'react-redux';
import {createStructuredSelector} from "reselect";
import compact from "lodash.compact";

import {selectLocale} from "../selectors/language";

const {
  REACT_APP_AVAILABLE_LANGUAGES,
  REACT_APP_DEFAULT_LANGUAGE,
} = process.env;

const languagesStr = REACT_APP_AVAILABLE_LANGUAGES || "";
const languages = languagesStr.split("|");

export const firstPathStartsWith = (startsWith, pathname) => {
  const pathnames = pathname.split("/");
  const isLangPath = languages.indexOf(pathnames[1]) !== -1;
  return startsWith.indexOf(pathnames[isLangPath ? 2 : 1] || "") !== -1;
};

export const secondPathStartsWith = (startsWith, pathname) => {
  const pathnames = pathname.split("/");
  const isLangPath = languages.indexOf(pathnames[1]) !== -1;
  return startsWith.indexOf(pathnames[isLangPath ? 3 : 2] || "") !== -1;
};


export const getLangLink = ({locale, to}) => {
  const lang = (REACT_APP_DEFAULT_LANGUAGE === locale ? "" : locale);
  const pathname = lang ? `/${lang}` : "";
  if (typeof to === "object") {
    return {
      ...to,
      pathname: `${pathname}${to.pathname}`,
    };
  }
  return `${pathname}${to}`;
};

export const getSelectedLang = (pn) => {
  const pathname = pn || window.location.pathname;
  const pathnames = pathname.split("/");
  return (languages.indexOf(pathnames[1]) === -1) ? REACT_APP_DEFAULT_LANGUAGE : pathnames[1];
};

export const getLink = ({locale, to}) => {
  const toLang = locale || getSelectedLang();
  const loc = to || window.location || {};
  const {pathname} = typeof loc === "object" ? loc : {pathname: loc};
  const pathnames = pathname.split("/");

  pathnames.shift();
  const isLangPath = languages.indexOf(pathnames[0]) !== -1;
  //let currentLanguage = "";
  if (isLangPath) {
    //currentLanguage = pathnames[0];
    pathnames.shift();
  }

  if (toLang !== REACT_APP_DEFAULT_LANGUAGE) {
    pathnames.unshift(toLang);
  }
  return `/${compact(pathnames).join("/")}${!to ? window.location.search : ""}`;
};

export const LangLink = connect(createStructuredSelector({
  selectLocale,
}))(({
  selectLocale,
  to,
  style,
  onClick,
  className,
  children,
  target,
}) => {
  const link = getLangLink({locale: selectLocale, to});
  return (
    <Link
      target={target}
      to={link}
      onClick={onClick}
      style={style}
      className={className}
    >
      {children}
    </Link>
  );
});

export const LangRedirect = connect(createStructuredSelector({
  selectLocale,
}))(({
  selectLocale,
  to,
}) => {
  const link = getLangLink({locale: selectLocale, to});
  return (
    <Redirect
      to={link}
    />
  );
});

import Nes from '@hapi/nes/lib/client';
import {match} from "path-to-regexp";
import {getToken} from "../lib/helpers";

import actionTypes  from "../constants/ActionTypes";


const {
  REACT_APP_WS_URL,
} = process.env;

const wsApi = REACT_APP_WS_URL.replace("http", "ws");
const connections = {};

const connect = async (path/*, dispatch*/) => {
  const url = `${wsApi}/${path}`;
  const Authorization = getToken();
  const auth = path === "private" ? {
    headers: {
      Authorization,
    },
  } : false;

  const connection = connections[path];

  const ws = connection ? connection.ws : new Nes.Client(url);

  try {
    await ws.connect({auth, reconnect: true});
    /*ws.onDisconnect = () => {
      setTimeout(() => {
        console.log("reconnecting");
        dispatch(init(path));
      }, 2000);
      };*/

    connections[path] = {
      ws,
      auth,
    };
  } catch (e) {
    /*setTimeout(() => {
      console.log("reconnecting");
      dispatch(init(path));
      }, 2000);*/
  }
  return connections[path] || {};

};
const getStreams = (dispatch) => ({
  "/daemon/token-activity": {
    handler: (value) => {
      dispatch({
        type: actionTypes.GET_TOKEN_ACTIVITY,
        value,
      });
    },
  },
});

const getStream = (path, dispatch) => {
  const streams = getStreams(dispatch);
  const selected = streams[path];

  if (selected) {
    return selected;
  }
  const found = Object.keys(streams).find((streamPath) => match(streamPath)(path));
  return streams[found];
};


export function init(path) {
  return async (dispatch) => {
    const {ws} = await connect(path, dispatch);
    if (!ws) {
      return;
    }
    console.log("connected");
    const subscriptions = ws.subscriptions();
    const streamKeys = subscriptions.length ? subscriptions : getStreams(dispatch);
    Object.keys(streamKeys).forEach((key) => {
      const paths = key.split("/");
      if (paths[1] !== path || key.indexOf(":") !== -1) {
        return;
      }
      dispatch(subscribe(key));
    });
  };
}

export function subscribe(path) {
  return (dispatch) => {
    const paths = path.split("/");
    const {ws} = connections[paths[1]] || {};
    if (!ws) {
      return setTimeout(() => dispatch(subscribe(path)), 2000);
    }
    const stream = getStream(path, dispatch) || {};
    ws.subscribe(path, stream.handler);
  };
}

export function unsubscribe(path) {
  return (dispatch) => {
    const paths = path.split("/");
    const {ws} = connections[paths[1]] || {};
    if (!ws) {
      return setTimeout(() => dispatch(unsubscribe(path)), 5000);
    }
    ws.unsubscribe(path);
  };
}

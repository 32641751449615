import { createSelector } from 'reselect';

const languageSelector = state => state.language;

export const selectLocale = createSelector(
  languageSelector,
  substate => substate.locale,
);

export const selectAvailable = createSelector(
  languageSelector,
  substate => substate.available,
);

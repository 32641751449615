import {OrderedMap} from "immutable";

export function insertOrderedMap(map, index, key, val) {
  return OrderedMap().withMutations((r) => {
    map.forEach((v, k) => {
      if (index === k) {
        r.set(key, val);
      }
      r.set(k, v);
    });
    return r;
  });
}

export const fillList = (list, type) => list.reduce((d, v) => d.set(v.id, v), type);

export const unshiftOrderedMap = (value) => (list) => {
  const first = list.first();
  if (!first) {
    return list.set(value.id, value);
  }
  const firstKey = list.first().id;
  if (list.get(value.id)) {
    return list.set(value.id, value);
  }
  const map = insertOrderedMap(list, firstKey, value.id, value);
  return map;
};

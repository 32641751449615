import intl from "react-intl-universal";

import {
  messages,
  errors,
} from "./messages";

const allMessages = Object.assign(
  messages,
  errors,
);

export const msg = (id, values) => (id && allMessages[id] ? intl.get(id, values) : (id || ""));
export const msgHTML = (id, values) => intl.getHTML(id, values);

export function getMessages(locale) {
  const languages = {[locale]: {}};
  Object.keys(allMessages).forEach((key) => {
    languages[locale][key] = messages[key][locale];
  });
  return languages;
}

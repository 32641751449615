import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {Col, Row} from "antd";

import Loading from "../components/Loading";
import Pagination from "../components/Pagination";
import {LangLink} from "../components/Language";
import {getQuery, getAssetImage} from "../lib/helpers";
import Text from "../components/Text";
import {msg} from "../messages";

export default ({
  token,
  name,
  page,
  limit,
  total,
  loading,
}) => {
  const tokenId = token.id;
  const assets = useSelector((state) => {
    const imAssets = state.asset.get(name);
    if (imAssets) {
      return imAssets
        .toIndexedSeq()
        .toArray()
        .slice(0, limit);
    }
    return [];
  });

  return (
    <div>
      <div className="mt50 relative">
        {loading && !assets.length && <Loading size={30} />}
        {(!loading || !!assets.length) &&  (
          <Row className="mt50" gutter="5">
            {assets.map((d) => (
              <Col xs={8} sm={6} md={6} lg={4} className="mb5" key={d.id}>
                <Card to={`/tokens/${token.slug}/${d.uri_param}`}>
                  <img src={getAssetImage(token.address, d, "400x0")} alt={`${name} ${d.uri_param}`} />
                  <div className="flex-h mt5">
                    <Text>#{d.uri_param}</Text>
                  </div>
                  <div className="flex-h">
                    <Text className="flex-1" bold>{msg("rarity")}</Text>
                    <Text>{d.score}</Text>
                  </div>

                </Card>
              </Col>
            ))}
          </Row>
        )}
      </div>
      {!!total && (
        <div className="mt20 mb20 text-center">
          <Pagination
            pageSize={Number(limit)}
            total={Number(total)}
            page={Number(page)}
            hideOnSinglePage={1}
            showQuickJumper={1}
            showSizeChanger={0}
          />
        </div>
      )}
    </div>
  );
};

const Card = styled(LangLink)`
  display: block;
  img {
    width: 100%;
  }
  padding: 10px;
  background: #333;
  border-radius: 5px;
`;

import actionTypes from "../constants/ActionTypes";
import API, {render} from "../constants/ApiEndpoints";
import {
  dispatchRequest,
} from "./ApiActions";

export function getAssetsByTokenId({
  name,
  tokenId,
} = {}, query) {
  return dispatchRequest({
    method: "get",
    url: render(API[actionTypes.GET_ASSETS_BY_TOKEN_ID], {tokenId}),
    params: query,
    include: {
      name,
      tokenId,
    },
    type: actionTypes.GET_ASSETS_BY_TOKEN_ID,
  });
}

export function getAsset(params) {
  return dispatchRequest({
    method: "get",
    url: render(API[actionTypes.GET_ASSET], params),
    include: params,
    type: actionTypes.GET_ASSET,
  });
}

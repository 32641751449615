import {Checkbox} from "antd";
import styled from "styled-components";

export default styled(Checkbox)`
  font-size: 11px !important;
  .ant-checkbox {
    display: flex;
    .ant-checkbox-inner {
      &:after {
      }
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {

  }
  &.ant-checkbox-wrapper {
    margin-left: 0 !important;
    font-size: 14px !important;
    display: flex !important;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  }
  .ant-checkbox-inner {
  }
  &:hover {
    .ant-checkbox-inner {
    }
  }
  .ant-checkbox-checked::after {
  }
  .ant-checkbox-inner::after {
  }
  span:last-child {
    padding: 0 0px;
    flex: 1;
  }
`;


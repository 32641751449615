// @flow
export default [
  "API_SET",

  "AUTH_POST_PASSWORD_CONFIRM_CODE",
  "AUTH_POST_PASSWORD_GENERATE_CODE",
  "AUTH_PUT_PASSWORD_RESET",
  "AUTH_POST_EMAIL_CONFIRM_CODE",
  "AUTH_POST_EMAIL_GENERATE_CODE",
  "AUTH_POST_CREATE_USER",
  "AUTH_POST_LOGIN",
  "AUTH_GET_ME",
  "AUTH_DELETE_SESSION",

  "AUTH_SIGNUP",
  "AUTH_SIGNUP_VERIFY",
  "AUTH_SIGNUP_PASSWORD",
  "AUTH_LOGIN",
  "AUTH_SET_TOKEN",
  "AUTH_SESSION",

  "MASTER_SET",
  "MASTER_SETTINGS_PUT",
  "MASTER_SETTINGS_GET",

  "GET_TOKEN_LIST",
  "GET_TOKEN_BY_SLUG",
  "GET_TOKEN_ACTIVITY",

  "GET_ASSETS_BY_TOKEN_ID",
  "GET_ASSET",
].reduce((
  actionTypes,
  type,
) => ({
  ...actionTypes,
  [type]: type,
}), {});

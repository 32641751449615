import React from "react";
import mapValues from "lodash.mapvalues";
import {Route} from "react-router-dom";
import Decimal from "decimal.js";
import {css} from "styled-components";
import qs from "qs";
import {matchPath} from "react-router";

import {history} from "../lib/configureStore";

const {
  REACT_APP_MINIO_URL,
  REACT_APP_IMG,
} = process.env;

let token = window.localStorage.getItem('token');

export const dimensionMap = {
  xs: '480px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1600px',
};

export const media = mapValues(dimensionMap, value => (...args) => css`
  @media (max-width: ${value}) {
    ${css(...args)}
  }`);

export const RouteWithSubRoutes = (route: Object) => (
  <Route
    path={route.path}
    render={props => (
      // pass the sub-routes down to keep nesting
      <route.component {...props} routes={route.routes} />
    )}
  />
);

export const getImg = (url, params) => {
  return `${REACT_APP_IMG}/${params}/${url}`;
};

export const getAssetImage = (address, asset, params) => {
  let img = asset.traits.image;
  if (asset && asset.has_image_cache) {
    img = `${REACT_APP_MINIO_URL}assets/${address}/${asset.uri_param}`;
  }
  if (params) {
    return getImg(img, params);
  }
  return img;
};

export function getToken() {
  token = token || window.localStorage.getItem('token');
  return token;
}

export function setToken(newToken?: String) {
  token = newToken;
  window.localStorage.setItem('token', token);
}

export const filterTable = (data, filters = {}) => (
  Object.keys(filters).reduce((t, v) => {
    if (typeof data[v] === "undefined") {
      return t;
    }
    if (!filters[v]) {
      return t && true;
    }
    if (filters[v] instanceof Array) {
      return t && filters[v].indexOf(data[v]) !== -1;
    }
    return t && data[v] === filters[v];
  }, true)
);


export const matchRoutes = (routes, history, store, token) => {
  let lang = null;
  const {pathname} = history.location;
  const query = qs.parse((history.location.search || "").substring(1));

  let match = {};
  const matches = (function reduceRoutesRecursive(arr, total = []) {
    arr.forEach((route) => {
      const matched = matchPath(pathname, route);
      if (matched) {
        if (matched.isExact) {
          match = matched;
        }
        lang = matched.params ? matched.params.lang : null;
        const fetchParams = {
          history,
          store,
          match: {...matched, query},
          token,
        };
        total.push({
          route,
          match: matched,
          promise: () => ("fetchData" in route.component
            // $FlowFixMe missing in statics: https://github.com/facebook/flow/issues/5875
            ? route.component.fetchData(fetchParams) : Promise.resolve(null)),
        });
      }
      if (route.routes && Array.isArray(route.routes)) {
        return reduceRoutesRecursive(route.routes, total);
      }
    });
    return total;
  }(routes, []));
  return {lang, match, matches, query};
};


export const promiseSeries = (funcs: Array<any>) => {
  funcs.reduce((promiseChain, currentTask) => (
    promiseChain.then(chainResults => (currentTask().then(currentResult => [...chainResults, currentResult])))
  ), Promise.resolve([]));
};


export const compactNumber = (number) => {
  const formatter = Intl.NumberFormat('en', { notation: 'compact' });
  return formatter.format(number);
};

export function addCommas(n: any, ifZero: any, precision: any = 18) {
  if (!ifZero && (!n && n !== 0)) {
    return "";
  }
  if (!Number(n)) { return n; }
  let parts = (n || 0).toString();
  parts = parts.split(".");
  const decimals = (parts[1] || "").substring(0, precision);
  let result = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  result += (precision && (decimals || typeof parts[1] !== "undefined") ? `.${decimals}` : "");
  return result === "0" || !result ? ifZero : result;
}

export const formatNumber = (value = 0, precision, decimalRound) => addCommas(new Decimal(value).toFixed(precision, decimalRound), new Decimal(0).toFixed(precision, decimalRound));

export const redGreen = (n) => (n < 0 ? "red" : "green");

export const getQuery = () => qs.parse((history.location.search || "").substring(1), {allowDots: true}) || {};


// @flow

import actionTypes from "../constants/ActionTypes";

export function set(key, value) {
  return {
    type: actionTypes.MASTER_SET,
    key,
    value,
  };
}

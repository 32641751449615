export const messages = {
  floor_price: {
    en: "Floor Price",
    ja: "フロアプライス",
  },
  total_volume: {
    en: "Total Volume",
    ja: "取引高",
  },
  rarity: {
    en: "rarity",
    ja: "ラリティ",
  },
  one_day_volume: {
    en: "Volume(24H)",
    ja: "取引高(24H)",
  },
  change_24h: {
    en: "24H %",
    ja: "24H %",
  },
  change_7d: {
    en: "7D %",
    ja: "7D %",
  },
};

export const errors = {
}

// @flow

import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {Menu as Mn, Layout, Badge} from "antd";
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';

import Logo from "../components/Logo";
import {getLink, LangLink} from "../components/Language";
import Text from "../components/Text";
import AssetsFilterForm from "../components/AssetsFilterForm";

import {msg} from "../messages";
import {history} from "../lib/configureStore";


export const Menu = () => {
  return (
    <Scrollbars
      style={{marginRight: 0}}
      autoHide={true}
      hideTracksWhenNotNeeded={true}
    >
      <MenuWrapper className="flex-v flex-1">
        <AssetsFilterForm />
      </MenuWrapper>
    </Scrollbars>
  );
}

const LanguageText = styled.a`
  display: block;
  font-size: 12px;
  cursor: pointer;
  color: rgba(255,255,255,0.5) !important;
  &:hover {
    color: rgba(255,255,255, 1) !important;
  }
`;

const MenuWrapper = styled.div`
  height: 100%;
  z-index: 99;
  background-color: ${props => props.theme.myPageMenuBackground} !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 2px 1px;
`;

const StyledMenuItem = styled(Mn.Item)`
  display: block;
  background-color: ${props => props.theme.myPageMenuBackground} !important;
  font-weight: 400;
  font-size: 15px !important;
  height: auto !important;
  padding: 0 !important;
  margin: 0 !important;
  border-bottom: 0;
  border-right: 0 !important;
  &:before {
    content: "";
  }
  a {
    font-family: "Open Sans", sans-serif !important;
    text-transform: uppercase;
    border-right: 0 !important;
    line-height: 48px;
    padding: 0px 10px;
    color: rgba(255, 255, 255, 0.5) !important;
    fill: rgba(255, 255, 255, 0.5) !important;
    border-left: 3px solid transparent;
    transition-duration: 0s;
    text-decoration: none;
    &.sign-out {
      border-top: 1px solid rgba(191, 223, 255, 0.2);
      margin-bottom: 5px;
    }
    &:hover {
      opacity: 0.8;
      background: rgba(128, 191, 255, 0.06);
      color: rgba(191, 223, 255, 1) !important;
      fill: rgba(191, 223, 255, 1) !important;
    }
    &:active {
      transform: scale(0.98);
    }
  }
  &.ant-menu-item-selected {
    background-color: ${props => props.theme.myPageMenuSelectedBackground} !important;
    transition-duration: 0s;
    a {
      color: white !important;
      fill: white !important;
      border-left: 3px solid #1b7aa7;
      &:active {
        transform: scale(1);
      }
    }
  }
`;

export const Sider = styled(Layout.Sider)`
  top: calc(${props => props.theme.headerHeight});
  height: calc(100vh - ${props => props.theme.headerHeight} + 2px);
  position: fixed;
  left: 0;
  //box-shadow: 0 1px 2px 0 rgba(0,0,0,0.24), 0 0 2px 0 rgba(0,0,0,0.12);
  z-index: 100;
  background: ${props => props.theme.headerColor};
  .ant-layout-sider-children {
    display: flex;
    flex: 1;
  }
  &.ant-layout-sider {
  }
  &.ant-layout-sider-collapsed {
    .ant-layout-sider-children {
      transition: none;
      transform: translateX(-300px);
    }
  }
  .ant-menu-item {
    transition: none;
  }
  .ant-layout-sider-zero-width-trigger {
    display: none;
  }
  .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border: 0;
  }
`;


const StyledBadge = styled(Badge)`
  .ant-badge-count {
    box-shadow: 0 0 0 1px rgb(255, 77, 79);
  }
`;


import React, {useEffect, useState} from "react";
import Helmet from "react-helmet";
import {useSelector, useDispatch} from "react-redux";
import useDeepCompareEffect from 'use-deep-compare-effect'

import Text from "../components/Text";
import {Body, Content, Wrapper} from "../components/UserPageLayout";
import Loading from "../components/Loading";
import TokenBanner from "../components/TokenBanner";
import {isMd as mdSelector} from "../selectors/master";
import * as TokenActions from "../actions/TokenActions";
import AssetsList from "../components/AssetsList";
import * as AssetActions from "../actions/AssetActions";
import {getQuery} from "../lib/helpers";

export default ({
  match: {
    params: {
      slug,
    },
  },
}) => {
  const {
    page,
    limit = 30,
    traits = {},
  } = getQuery()
  const dispatch = useDispatch();
  const isMd = useSelector(mdSelector);
  const token = useSelector((state) => state.token.get("getTokenBySlug").get(slug));
  const [loading, setLoading] = useState();
  const assetsKey = slug;

  const {total} = useSelector((state) => state.asset.get(`${assetsKey}Pagination`) || {});

  const loadAssets = async (page, limit, traits) => {
    if (!token || !token.id) { return; }
    setLoading(true);
    await dispatch(AssetActions.getAssetsByTokenId({tokenId: token.id, name: assetsKey}, {page, limit, traits}));
    setLoading(false);
  };

  const loadData = async () => {
    await dispatch(TokenActions.getTokenBySlug({slug}));
  };

  useEffect(() => {
    loadData();
  }, []);
  useDeepCompareEffect(() => {
    loadAssets(page, limit, traits)
  }, [page, limit, traits, token]);

  if (!token) {
    return (
      <div className="mt100">
        <Loading />
      </div>
    );
  }

  const {
    name,
    description,
  } = token;

  return (
    <Wrapper>
      <Helmet title={name} />
      <Body>
        <TokenBanner token={token} />
        <Content type="fluid">
          <div className={isMd ? "mt20 text-center" : "maxw650 mt15"}>
            <Text>{description}</Text>
          </div>
          <AssetsList
            token={token}
            name={assetsKey}
            total={total}
            page={page}
            limit={limit}
            traits={traits}
            loading={loading}
          />
        </Content>
      </Body>
    </Wrapper>
  );
};

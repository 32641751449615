import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'

import React from 'react';
import qs from "qs";
import {Provider} from "react-redux";
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { ConnectedRouter } from 'connected-react-router';
import ReactGA from "react-ga4";

import "./styles/helpers.css";
import "./styles/fonts.css";

import configureStore, {history} from "./lib/configureStore";
import App from './components/App';
import {promiseSeries, matchRoutes} from "./lib/helpers";
import reportWebVitals from './reportWebVitals';
import defaultTheme from "./styles/defaultTheme";
import routes from "./routes";

const store = configureStore();

const {
  REACT_APP_GA_ID,
} = process.env;

console.log(REACT_APP_GA_ID);
ReactGA.initialize(REACT_APP_GA_ID);

const onHistory = async (store) => {
  const {pathname, search = ""} = history.location;
  /*if (typeof gtag === 'function' && GA_TRACKING_ID) {
    window.gtag('config', GA_TRACKING_ID, {
      page_location: window.location.href,
      page_path: pathname,
    });
  }*/
  ReactGA.send({ hitType: "pageview", page: pathname + search});

  const query = qs.parse((history.location.search || "").substring(1));
  //const {token} = localStorage;
  const token = null;
  const {matches, lang} = matchRoutes(routes, history, store, token);

  if (matches.length !== 0) {
    const promises = matches.map(match => match.promise);
    if ("fetchData" in App) {
      promises.unshift(() => App.fetchData({
        store,
        match: {
          params: {
            lang,
          },
          query,
        },
        token,
      }));
    }
    await promiseSeries(promises);
  }
};

onHistory(store);
history.listen(() => onHistory(store));


ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={defaultTheme}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import styled from "styled-components";
import {media} from "../lib/helpers";

const menuWidth = "238px";

export const Wrapper = styled.div`
  margin: 0 0 0 calc(${menuWidth});
  max-width: 100%;
  border-radius: 3px;

  .non-fluid {
    max-width: 980px;
  }


  ${media.lg`
    max-width: 100%;
    margin: 0px;
    border-radius: 0;
  `}
`;

export const Header = styled.div`
  min-height: ${props => props.theme.headerHeight};
  line-height: 1;
  vertical-align: middle;
  background: #333;
  color: white;
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 2px 1px;
  padding-left: calc(${menuWidth} + 15px);
  max-width: 100%;
  border-radius: 0;
  padding-right: calc(100% - 850px - ${menuWidth} - 15px);

  ${media.lg`
    padding-left: 60px;
    max-width: 100%;
    margin: 0px;
    border-radius: 0;
  `}

`;

export const Body = styled.div`
`;

export const Content = styled.div`
  max-width: ${props => (props.type === "fluid" ? "100%" : "850px")};
  overflow: hidden;
  background: ${props => props.theme.boxBgColor};
  border-radius: 3px;
  padding: 0 15px;
`;

export const ContentTransparent = styled.div`
  max-width: ${props => (props.type === "fluid" ? "100%" : "850px")};
  overflow: hidden;
`;


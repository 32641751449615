import actionTypes from "../constants/ActionTypes";
import API, {render} from "../constants/ApiEndpoints";
import {
  dispatchRequest,
} from "./ApiActions";

export function getTokenList({
  name,
  ...params
} = {}) {
  return dispatchRequest({
    method: "get",
    url: API[actionTypes.GET_TOKEN_LIST],
    params,
    include: {
      name,
    },
    type: actionTypes.GET_TOKEN_LIST,
  });
}

export function getTokenBySlug({
  slug,
} = {}, query) {
  return dispatchRequest({
    method: "get",
    url: render(API[actionTypes.GET_TOKEN_BY_SLUG], {slug}),
    type: actionTypes.GET_TOKEN_BY_SLUG,
    params: query,
    include: {
      slug,
    },
  });
}


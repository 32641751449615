import { ConfigProvider } from "antd";
import {connect} from "react-redux";
import Helmet from "react-helmet";
import {compose, lifecycle} from "recompose";
import {withRouter, Switch} from "react-router-dom";
import {createStructuredSelector} from "reselect";
import enUS from 'antd/lib/locale-provider/en_US';
import jaJP from 'antd/es/locale/ja_JP'

import {selectLocale} from "../selectors/language";
import * as MasterActions from "../actions/MasterActions";
import {matchMedia} from "../selectors/master";
import * as LanguageActions from "../actions/LanguageActions";
import ScrollToTop from '../components/ScrollToTop';

import routes from "../routes";
import {
  RouteWithSubRoutes,
  matchRoutes,
} from "../lib/helpers";
import {history} from "../lib/configureStore";

const {
  lang = "en",
} = matchRoutes(routes, history);
const locales = {
  en: enUS,
  ja: jaJP,
};

const App =  ({
  dispatch,
  selectLocale,
}) => {
  return (
    <ConfigProvider>
      <ScrollToTop />
      <Helmet
        defaultTitle="rarerave.com: NFT RAVE"
        titleTemplate="%s - rarerave.com"
      />
      {routes.map((route, i) => (
        <Switch key={i}>
          <RouteWithSubRoutes
            key={i}
            {...route}
          />
        </Switch>
      ))}
    </ConfigProvider>
  );
}

export default compose(
  //hot(module),
  withRouter,
  connect(createStructuredSelector({
    selectLocale,
    matchMedia,
  })),
  lifecycle({
    componentDidMount() {
      const {
        matchMedia,
        dispatch,
      } = this.props;
      dispatch(LanguageActions.setLanguage(lang));
      matchMedia.lg.addListener(({matches}) => {
        dispatch(MasterActions.set("isLg", matches));
      });
      matchMedia.md.addListener(({matches}) => {
        dispatch(MasterActions.set("isMd", matches));
      });
      matchMedia.sm.addListener(({matches}) => {
        dispatch(MasterActions.set("isSm", matches));
      });
      window.addEventListener('resize', () => {
        const {
          innerHeight,
          innerWidth,
        } = window;
        dispatch(MasterActions.set("height", innerHeight));
        dispatch(MasterActions.set("width", innerWidth));
      });
    },
    componentDidUpdate(prev) {
      const {
        dispatch,
        location,
      } = this.props;
      if (prev.location.pathname !== location.pathname) {
        //loadData(dispatch);
      }
    },
  }),
)(App);

// @flow

import {Map, OrderedMap} from "immutable";

import {storageUrl} from "../lib/helpers";

import actionTypes from "../constants/ActionTypes";
import {
  fillList,
} from "../lib/immutableHelpers";

const InitialState = new Map({
  getAsset: new OrderedMap(),
  getAssetsByTokenId: new OrderedMap(),
  assetsPagination: {
    page: 0,
    total: 0,
    perPage: 0,
  },
});

export default function (state: Object = InitialState, action: Object = {}) {
  const {
    value,
    type,
    params,
  } = action;
  switch (type) {
    case actionTypes.GET_ASSET: {
      const {slug, uri_param} = params;
      return state.
        setIn([
          "getAsset",
          `${slug}_${uri_param}`,
        ],
          value,
        )
    }
    case actionTypes.GET_ASSETS_BY_TOKEN_ID: {
      const {rows, count} = value;
      return state
        .set(
          params.name || "getAssetsByTokenId",
          fillList(rows, new OrderedMap()),
        )
        .set(
          params.name ? `${params.name}Traits` : "getAssetTraits",
          Object.keys(value.traits).map(trait => ({
            name: trait,
            subs: Object.keys(value.traits[trait]).map(sub => ({
              name: sub,
              total: value.traits[trait][sub],
            })).sort((a, b) => a.total - b.total),
          })),
        )
        .set(params.name ? `${params.name}Pagination` : "assetsPagination", {
          page: params.page,
          liimt: params.limit,
          total: count,
        });
    }
    default:
      return state;
  }
}

import intl from 'react-intl-universal';
import {getMessages} from "../messages";

export const setLanguage = (lang = process.env.REACT_APP_DEFAULT_LANGUAGE) => {
  const locales = getMessages(lang);
  intl.init({
    currentLocale: lang,
    locales,
  });
  return {
    lang,
    type: "LANGUAGE_SET",
  };
};

export const setLocation = location => ({
  location,
  type: "LOCATION_SET",
});

import { createSelector } from 'reselect';

const masterSelector = state => state.master;

export const width = createSelector(
  masterSelector,
  substate => substate.get("width"),
);

export const height = createSelector(
  masterSelector,
  substate => substate.get("height"),
);

export const isLg = createSelector(
  masterSelector,
  substate => substate.get("isLg"),
);

export const isMd = createSelector(
  masterSelector,
  substate => substate.get("isMd"),
);

export const isSm = createSelector(
  masterSelector,
  substate => substate.get("isSm"),
);
export const isXs = createSelector(
  masterSelector,
  substate => substate.get("isXs"),
);
export const matchMedia = createSelector(
  masterSelector,
  substate => substate.get("matchMedia"),
);

export const selectTempSignup = createSelector(
  masterSelector,
  substate => substate.get("tempSignup"),
);

export const tempLogin = createSelector(
  masterSelector,
  substate => substate.get("tempLogin"),
);

export const tempForgot = createSelector(
  masterSelector,
  substate => substate.get("tempForgot"),
);

export const postPasswordConfirmCode = createSelector(
  masterSelector,
  substate => substate.get("postPasswordConfirmCode"),
);

export const getSankyuRateList = createSelector(
  masterSelector,
  substate => substate.get("getSankyuRateList"),
);


import React from 'react';
import styled from "styled-components";
import {Switch} from "react-router-dom";

//import {history} from "../lib/configureStore";
import Header from "../components/Header";
import {RouteWithSubRoutes} from "../lib/helpers";
import Footer from "../components/Footer";
import {MainContent} from "../components/Layout"

export default ({
  routes,
}) => (
  <>
    <Header /*history={history}*/ />
    <MainContent>
      {routes.map((route, i) => route.component && (
        <Switch key={i}>
          <RouteWithSubRoutes {...route} />
        </Switch>
      ))}
      <Footer />
    </MainContent>
  </>
);
// @flow
import {Record} from "immutable";

import actionTypes from "../constants/ActionTypes";
import {dimensionMap} from "../lib/helpers";

const xs = window.matchMedia(`(max-width: ${dimensionMap.xs})`);
const sm = window.matchMedia(`(max-width: ${dimensionMap.sm})`);
const md = window.matchMedia(`(max-width: ${dimensionMap.md})`);
const lg = window.matchMedia(`(max-width: ${dimensionMap.lg})`);

const InitialState = new Record({
  width: window.innerWidth,
  height: window.innerHeight,
  matchMedia: {
    xs,
    sm,
    md,
    lg,
  },
  isMd: md.matches,
  isLg: lg.matches,
  isSm: sm.matches,
  isXs: xs.matches,
  locales: {},
  locale: null,
  tempSignup: false,
  tempForgot: false,
  tempSignin: false,
  postPasswordConfirmCode: false,
  getMasterSettings: null,
});

export default function api(state: Object = new InitialState(), action: Object = {}) {
  switch (action.type) {
    case actionTypes.MASTER_SET:
      return state.set(action.key, action.value);
    case actionTypes.MASTER_SETTINGS_GET:
    case actionTypes.MASTER_SETTINGS_PUT: {
      return state.set("getMasterSettings", action.value);
    }

    default:
      return state;
  }
}

export default {
  background: "#222",
  headerColor: "#313131",
  headerHeight: "50px",
  maxWidth: "940px",
  colors: {
    white: "white",
    green: "#16c784",
    red: "#fb475b",
  }
}

// @flow

import qs from "qs";
import {message} from "antd";

import actionTypes from "../constants/ActionTypes";
import {getToken, setToken} from "../lib/helpers";
import api from "../constants/ApiEndpoints";
import {msg} from "../messages";

const {
  API_SET,
  AUTH_GET_ME,
} = actionTypes;

const headers = {
  "Content-Type": 'application/json',
  Accept: "application/json",
};

const reqFunctions = {
  post: apiPost,
  get: apiGet,
  delete: apiDelete,
  patch: apiPatch,
  put: apiPut,
};

export const dispatchRequest = ({
  method,
  url,
  type,
  params,
  onError: error,
  include = {},
  opts = {},
}) => (
  async (dispatch) => {
    const m = method.toLowerCase();
    const request = reqFunctions[m](url, params, opts);
    const response = await dispatch(request);
    if (!response.error) {
      dispatch({
        type,
        value: response,
        params: {
          ...params,
          ...include,
        },
      });
    }
    if (response.error) {
      if (error) {
        error(dispatch);
      } else {
        dispatch(onError(response));
      }
      return {error: true};
    }
    return response;
  }
);

function onRequest() {
  return {
    type: API_SET,
    key: "loading",
    value: true,
  };
}

function onResponse(response: Object) {
  return (dispatch: any) => {
    dispatch({type: API_SET, key: "loading", value: false});
    dispatch({type: API_SET, key: "success", value: response});
    return response;
  };
}


function onError(response: Object) {
  return (dispatch: any, getState) => {
    dispatch({type: API_SET, key: "loading", value: false});
    let errorObj = {};
    //console.log(response);
    if (response instanceof Error || typeof response === "string") {
      // Unexpected errors
      const detail = msg("error_unexpected");
      errorObj = {
        error: "unexpected",
        detail,
        meta: null,
      };
      message.error({
        content: detail,
        key: "unexpected",
      });

    } else {
      // expect error in JSON format
      const {
        error = "unexpected",
        key,
        detail,
        id,
        meta = {},
        status,
        statusCode, // default error from hapi
      } = response;

      errorObj = {
        error,
        key,
        id,
        status,
        detail,
        meta,
      };
      if (statusCode && statusCode !== 401) {
        message.error({
          content: msg(`error_${statusCode}`),
          key: statusCode,
        });
      } else if (statusCode === 401) {
        errorObj.error = "error_401";
      } else if (detail) {
        message.error({
          content: detail,
          key: error,
        });

      }

    }
    const hidden = errorObj.meta && errorObj.meta.hidden;

    if (errorObj.key && !hidden) {
      message.error({
        content: msg(errorObj.key) || errorObj.detail,
        duration: 10,
        key: errorObj.key,
      });
    }
    if (errorObj.meta && errorObj.meta.guest) {
      localStorage.setItem("guest", errorObj.meta.guest);
    }
    const {router: {action}} = getState();

    if (errorObj.error.toLowerCase() === "error_401") {
      dispatch({type: AUTH_GET_ME, value: {}});
      setToken(null);
    }
    if (action === "REPLACE") {
      message.error({
        content: errorObj.error ? msg(errorObj.error) : errorObj.detail,
        duration: 10,
        key: errorObj.error,
      });
    }
    dispatch({type: API_SET, key: "error", value: errorObj});
    return errorObj;
  };
}

function apiFetch(url, options) {
  return async (dispatch: Function) => {
    const {
      headers: {
        ...headers
      },
      multipart,
      ...opts
    } = options;
    if (multipart) {
      delete headers["Content-Type"];
    } else {
      opts.body = JSON.stringify(opts.body);
    }

    try {
      const response = await fetch(url, {
        ...opts,
        headers,
      });
      const authToken = response.headers.get("authorization") || response.headers.get("Authorization");
      //console.log(authToken, response.headers, response);
      if (authToken) {
        setToken(authToken);
      }
      const json = opts.blob ? response : await response.json();

      if (response.status >= 200 && response.status < 300) {
        return dispatch(onResponse(json));
      }
      return dispatch(onError(json));
    } catch (error) {
      if (error.response) {
        return dispatch(onError(error.response.data));
      } else if (error.request) {
        return dispatch(onError(error));
      }
      return dispatch(onError(error));
    }
  };
}

export function apiGet(url: string, query?: Object, opts?: Object) {
  const token = getToken();
  const options = {
    ...opts,
    method: "GET",
    headers: {...headers, Authorization: token},
  };
  const queryString = query && `?${qs.stringify(query, { allowDots: true })}`;
  return (dispatch: Function) => {
    dispatch(onRequest());
    return dispatch(apiFetch(`${url}${queryString || ""}`, options));
  };
}

export function apiPost(url: string, query?: Object, opts?: Object) {
  const token = getToken();
  const options = {
    ...opts,
    method: "POST",
    headers: {...headers, Authorization: token},
    body: query,
  };
  return (dispatch: Function) => {
    dispatch(onRequest());
    return dispatch(apiFetch(url, options));
  };
}

export function apiDelete(url: string, query?: Object, opts?: Object) {
  const token = getToken();
  const options = {
    ...opts,
    method: "DELETE",
    headers: {...headers, Authorization: token},
  };
  const queryString = query && `?${qs.stringify(query, { allowDots: true })}`;
  return (dispatch: Function) => {
    dispatch(onRequest());
    return dispatch(apiFetch(`${url}${queryString || ""}`, options));
  };
}

export function apiPut(url: string, query?: Object, opts?: Object) {
  const token = getToken();
  const options = {
    ...opts,
    method: "PUT",
    headers: {...headers, Authorization: token},
    body: query,
  };
  return (dispatch: Function) => {
    dispatch(onRequest());
    return dispatch(apiFetch(url, options));
  };
}

export function apiPatch(url: string, query?: Object, opts?: Object) {
  const token = getToken();
  const options = {
    ...opts,
    method: "PATCH",
    headers: {...headers, Authorization: token},
    body: query,
  };
  return (dispatch: Function) => {
    dispatch(onRequest());
    return dispatch(apiFetch(url, options));
  };
}


export function getValidationSchemas() {
  return async (dispatch: Function) => {
    const result = await dispatch(apiGet(api.validations.get.schemas));
    dispatch({type: API_SET, value: result, key: "validations"});
  };
}


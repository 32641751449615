import {useState} from "react";
import qs from "qs";
import {Form} from "antd";
import {useSelector} from "react-redux";
import styled from "styled-components";

import Checkbox from "../components/Checkbox";
import Button from "../components/Button";
import routes from "../routes";
import {
  matchRoutes,
  getQuery,
} from "../lib/helpers";
import {history} from "../lib/configureStore";

export default ({
  onFilter,
}) => {
  const query = getQuery() || {};
  const [opens, setOpens] = useState(
    Object.keys(query.traits || {}).reduce((obj, trait) => {
      return {
        ...obj,
        [trait]: true,
      };
    }, {}));
  const router = useSelector(state => state.router);
  const {
    match: {
      params,
    },
  } = matchRoutes(routes, router);
  const onTrait = (index) => {
    setOpens({
      ...opens,
      [index]: !opens[index],
    });
  };
  const traits = useSelector(state => state.asset.get(`${params.slug}Traits`)) || [];

  const onSubmit = (changed, values) => {
    const search = qs.stringify({
      traits: values,
    });
    history.push({
      search,
    });
  };

  return (
    <Form
      onValuesChange={onSubmit}
      initialValues={query.traits}
    >
      <div className="flex-h ph15 white bold">
        <div className="flex-1">Traits</div>
        <div>
          <Button size="xxs">
            Filter
          </Button>
        </div>
      </div>
      {traits.map(({
        name,
        subs,
      }) => (
        <div key={name}>
          <Trait onClick={() => onTrait(name)} className="flex-h">
            <div className="mr5">{opens[name] ? "▼" : "▶"}</div>
            <div>{name}</div>
          </Trait>
          {opens[name] && (
            <Form.Item
              name={name}
              noStyle
            >
              <Checkbox.Group>
                {subs.map(({
                  name,
                  total,
                }) => (
                  <CheckboxWrapper key={name}>
                    <Checkbox value={name}>
                      <Sub className="flex-h">
                        <div className="flex-1">{name}</div>
                        <div>{total}</div>
                      </Sub>
                    </Checkbox>
                  </CheckboxWrapper>
                ))}
              </Checkbox.Group>
            </Form.Item>
          )}
        </div>
      ))}
    </Form>
  );
};


const Trait = styled.div`
  padding: 2px 15px;
  color: #48c248;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
`;

const Sub = styled.div`
  padding: 0px;
  margin-left: 10px;
  cursor: pointer;
  color: white;
  user-select: none;
`;

const CheckboxWrapper = styled.div`
  margin: 0 15px;
  padding: 1px 7px;
  border-radius: 10px;
  &:hover {
    background: #111;
  }
`;

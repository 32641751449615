import React from "react";
import qs from "qs";
import {Pagination as AntPagination} from "antd";
import styled from "styled-components";

import {LangLink} from "../components/Language";
import {getQuery} from "../lib/helpers";
import {history} from "../lib/configureStore";

export const paginationStyle = `
  &.ant-pagination { 
    svg {
      fill: white;
    }
    .ant-pagination-options-quick-jumper {
      color: white;
    }
    li {
      border-radius: 5px;
      border: 0;
      background: #2E2E2E !important;
      .ant-pagination-item-link {
        background: #2e2e2e !important;
        border: 0;
        border-radius: 5px;
      }
      &.ant-pagination-item-active {
        background: #BC99F6 !important;
        a {
          color: black !important;
        }
      }
      &:hover {
        background: #474747 !important;;
      }
      a {
        color: white !important;
      }
    }
  }
`;

export const itemRender = (page, type) => {
  const {
    limit,
    filters,
  } = getQuery();

  const {
    location: {
      pathname,
    },
  } = history;

  const query = qs.stringify({
    page,
    filters,
    limit,
  }, {
    addQueryPrefix: true,
  });
  let text = page;

  if (type === "prev") {
    text = "<";
  }

  if (type === "next") {
    text = ">";
  }

  if (type === "jump-prev") {
    text = "...";
  }

  if (type === "jump-next") {
    text = "...";
  }
  return (
    <LangLink to={`${pathname}${query}`}>{text}</LangLink>
  );
};

const Pagination = (props) => {

  return (
    <AntPagination
      {...props}
      itemRender={itemRender}
    />
  );
};

export default styled(Pagination)`
  ${paginationStyle}
`;

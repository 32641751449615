// @flow

import React, { Component } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Switch/*, Redirect*/} from "react-router-dom";
import {Layout} from "antd";
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import {BarsOutlined} from '@ant-design/icons';

import * as UserPageMenu from "../components/UserPageMenu";
import * as WsActions from "../actions/WsActions";
import {RouteWithSubRoutes} from "../lib/helpers";
import Header from "../components/Header";
import {isLg} from "../selectors/master";

//import {getUsersInformation} from "../actions/UsersActions";

const breakpoint = "lg";

type Props = {
  routes: Array<Object>,
};

type State = {
  collapsed: boolean,
};

export default ({
  routes,
}) => {
  const dispatch = useDispatch();
  const lg = useSelector(isLg);
  const [collapsed, setCollapsed] = React.useState(false);
  const onSelect = () => {
    if (lg) {
      setCollapsed(true);
    }
  };
  const onCollapse = (collapsed: boolean, type: string) => {
    if (type === "responsive") {
      setCollapsed(collapsed);
    }
    if (type === "clickTrigger") {
      setCollapsed(!collapsed);
    }
  };
  React.useEffect(() => {
    dispatch(WsActions.init("private"));
    dispatch(WsActions.init("daemon"));
  }, []);

  return (
    <>
      <Header /*history={history}*/ />
      <MainContent>
        {(lg) && (
          <CollapseTrigger onClick={() => { onCollapse(collapsed, "clickTrigger"); }}>
            <BarsOutlined style={{color: "white"}} />
          </CollapseTrigger>
        )}
        <UserPageMenu.Sider
          breakpoint={breakpoint}
          collapsedWidth="0"
          onCollapse={onCollapse}
          width={238}
          collapsible={false}
          collapsed={collapsed}
        >
          <UserPageMenu.Menu
            location={location}
            onSelect={onSelect}
          />
        </UserPageMenu.Sider>
        <StyledContent>
          {routes.map((route, i) => route.component && <Switch key={i}><RouteWithSubRoutes {...route} /></Switch>)}
          {((lg) && !collapsed) && <CollapseShadow onClick={() => setCollapsed({collapsed: true})} role="presentation" />}
        </StyledContent>
      </MainContent>
    </>
  );
};

const StyledContent = styled(Layout.Content)`
  background: ${props => props.theme.mainContentBgColor};
`;

const CollapseTrigger = styled.div`
  cursor: pointer;
  text-align: center;
  z-index: 99;
  border-radius: 0;
  border-right: 1px solid #222;
  position: fixed;
  top: 0;
  left: 0;
  color: rgba(230, 242, 255, 0.7);
  background: transparent !important;
  width: calc(${props => props.theme.headerHeight} + 0px);
  height: calc(${props => props.theme.headerHeight} + 0px);
  line-height: calc(${props => props.theme.headerHeight} + 0px);
  font-size: 25px;
`;

const CollapseShadow = styled.div`
  z-index: 11;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: 0.1s all;
`;

const MainContent = styled(Layout)`
  min-height: calc(100vh);
  background: ${props => props.theme.background};
`;


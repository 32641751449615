// @flow

import {Record} from "immutable";

import actionTypes from "../constants/ActionTypes";

const InitialState = Record({
  error: false,
  success: false,
  loading: false,
});

export default function api(state: Object = new InitialState(), action: Object = {}) {
  switch (action.type) {
    case actionTypes.API_SET:
      return state.set(action.key, action.value);
    default:
      return state;
  }
}

// @flow
const {
  REACT_APP_API_URL,
  REACT_APP_API_PATH,
  REACT_APP_API_AUTH_PATH,
} = process.env;
const endpoints = {
  // AUTH
  AUTH_POST_EMAIL_CONFIRM_CODE: `${REACT_APP_API_AUTH_PATH}/signup/verify`,
  AUTH_POST_EMAIL_GENERATE_CODE: `${REACT_APP_API_AUTH_PATH}/signup/generate`,
  AUTH_POST_CREATE_USER: `${REACT_APP_API_AUTH_PATH}/signup`,
  AUTH_POST_LOGIN: `${REACT_APP_API_AUTH_PATH}/login`,
  AUTH_GET_ME: `${REACT_APP_API_AUTH_PATH}/me`,
  AUTH_POST_PASSWORD_GENERATE_CODE: `${REACT_APP_API_AUTH_PATH}/password/generate`,
  AUTH_POST_PASSWORD_CONFIRM_CODE: `${REACT_APP_API_AUTH_PATH}/password/confirm`,
  AUTH_PUT_PASSWORD_RESET: `${REACT_APP_API_AUTH_PATH}/password/reset`,
  AUTH_DELETE_SESSION: `${REACT_APP_API_AUTH_PATH}/logout`,

  GET_TOKEN_LIST: `${REACT_APP_API_PATH}/token/list`,
  GET_TOKEN_BY_SLUG: `${REACT_APP_API_PATH}/token/{slug}`,
  GET_ASSETS_BY_TOKEN_ID: `${REACT_APP_API_PATH}/asset/{tokenId}`,
  GET_ASSET: `${REACT_APP_API_PATH}/asset/{slug}/{uri_param}`,
};

function applyRecursive(o: Object) {
  const obj = o;
  Object.keys(obj).forEach((key) => {
    const v = obj[key];
    if (typeof v === "string") {
      obj[key] = `${REACT_APP_API_URL}${v}`;
    } else if (typeof v === "function") {
      obj[key] = (...args) => `${REACT_APP_API_URL}${v(...args)}`;
    } else {
      obj[key] = applyRecursive(obj[key]);
    }
  });
  return obj;
}
export default applyRecursive(endpoints);

export const render = (str, obj) => (
  Object.keys(obj).reduce((p, c) => p.split(`{${c}}`).join(obj[c] || ""), str)
);

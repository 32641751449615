import BasePage from "./pages/BasePage";
import HomePage from "./pages/HomePage";
import UserPage from "./pages/UserPage";
import TokenPage from "./pages/TokenPage";
import AssetPage from "./pages/AssetPage";

const languagesStr = process.env.REACT_APP_AVAILABLE_LANGUAGES || "";

const toLanguageRoutes = (r) => {
  const routes = [];
  r.forEach((route) => {
    const languageRoute = {
      ...route,
      path: `/:lang(${languagesStr})${route.path}`,
      routes: route.routes && toLanguageRoutes(route.routes),
    };
    routes.push(languageRoute);
  });
  return routes;
};

const innerRoutes = [{
  path: "/",
  component: BasePage,
  exact: true,
  routes: [{
    path: "/",
    component: HomePage,
    exact: true,
  }]
}, {
  path: "/tokens/:slug/:uri_param",
  component: BasePage,
  exact: true,
  routes: [{
    path: "/tokens/:slug/:uri_param",
    component: AssetPage,
    exact: true,
  }],
}, {
  path: "/tokens/:slug",
  component: UserPage,
  exact: true,
  routes: [{
    path: "/tokens/:slug",
    component: TokenPage,
    exact: true,
  }],
}];

const combinedRoutes = [
  ...innerRoutes,
  ...toLanguageRoutes(innerRoutes),
];

export default combinedRoutes;

// @flow

import {Map, OrderedMap} from "immutable";

import {storageUrl} from "../lib/helpers";

import actionTypes from "../constants/ActionTypes";
import {
  fillList,
} from "../lib/immutableHelpers";

const InitialState = new Map({
  getTokenList: new OrderedMap(),
  tokensPagination: {
    page: 0,
    total: 0,
    perPage: 0,
  },
  getTokenBySlug: new OrderedMap(),
});

export default function (state: Object = InitialState, action: Object = {}) {
  const {
    value,
    type,
    params,
  } = action;
  switch (type) {
    case actionTypes.GET_TOKEN_LIST: {
      const {rows, count} = value;
      return state
        .setIn(
          [params.name || "getTokenList", Number(params.page)],
          fillList(rows, new OrderedMap()),
        )
        .set(params.name ? `${params.name}Pagination` : "tokensPagination", {
          page: params.page,
          liimt: params.limit,
          total: count,
        });
    }
    case actionTypes.GET_TOKEN_BY_SLUG: {
      const {slug} = value;
      return state.update("getTokenBySlug", token => token.set(slug, value));
    }

    default:
      return state;
  }
}



// @flow

import {combineReducers} from "redux";
import { connectRouter } from 'connected-react-router';

import api from "./api";
import token from "./token";
import asset from "./asset";
import language from "./language";
import master from "./master";

export default history => combineReducers({
  api,
  token,
  asset,
  language,
  master,
  router: connectRouter(history),
});

import styled from "styled-components"
import {useSelector} from "react-redux";

import Logo from "../components/Logo";
import {LangLink} from "../components/Language";

import HeaderProgress from "../components/HeaderProgress";

export default () => {
  const isLg = useSelector((state) => state.master.get("isLg"));
  const loading = useSelector((state) => state.api.get("loading"));

  return (
    <Header className={isLg ? "flex-h flex-center" : "flex-h flex-start"}>
      <HeaderProgress isAnimating={loading} />
      <div className="flex-v flex-center">
        <LangLink to="/">
          <Logo />
        </LangLink>
      </div>
    </Header>
  );
}

const Header = styled.div`
  position: fixed;
  padding: 0 15px;
  left: 0;
  right: 0;
  top: 0;
  z-index: 22;
  background: ${props => props.theme.headerColor};
  height: ${props => props.theme.headerHeight};
`;

const messages = require("../messages");

const languages = {
  en: {
    name: "English",
  },
  ja: {
    name: "日本語",
  },
};

const {
  REACT_APP_AVAILABLE_LANGUAGES,
} = process.env;

const available = REACT_APP_AVAILABLE_LANGUAGES.split("|").reduce(
  (acc, curr) => ({
    [curr]: languages[curr],
    ...acc,
  }),
  {},
);

export default function languageReducer(state = {
  available,
  locale: null,
  msg: {},
}, action) {
  switch (action.type) {
    case "LANGUAGE_SET": {
      const locale = action.lang;
      const localeMessages = {};
      Object.keys(messages).forEach((key) => {
        localeMessages[key] = messages[key][locale];
      });
      return Object.assign({}, state, {locale, msg: localeMessages});
    }
    default:
      return state;
  }
}

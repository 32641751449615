import React from "react";
import styled from "styled-components";

import {useSelector, useDispatch} from "react-redux";
import {SocialIcon} from "react-social-icons";
import Text from "../components/Text";
import {LangLink} from "../components/Language";
import {
  GlobalOutlined,
} from '@ant-design/icons';
import {Avatar} from "antd";

import EtherscanDark from "../assets/etherscan-dark.svg";

import {isMd as mdSelector} from "../selectors/master";

export default ({
  title,
  height,
  token: {
    id,
    name,
    slug,
    address,
    banner_image_url,
    image_url,
    discord_url,
    external_url,
    medium_username,
    twitter_username,
    instagram_username,
  },
}) => {
  const isMd = useSelector(mdSelector);
  const iconSize = 25;
  const linkKeys = {
    etherscan: {
      url: address && `https://etherscan.com/address/${address}`,
      icon: <img src={EtherscanDark} alt="etherscan" width={iconSize} height={iconSize} />,
    },
    url: {
      url: external_url && external_url,
      icon: <GlobalOutlined style={{fontSize: iconSize}} />,
    },
    discord: {
      url: discord_url && discord_url,
      icon: <SocialIcon network="discord" style={{width: iconSize, height: iconSize}} />,
    },
    medium: {
      url: medium_username && `https://www.medium.com/@${medium_username}`,
      icon: <SocialIcon network="medium" bgColor="white" style={{width: iconSize, height: iconSize}} />,
    },
    twitter: {
      url: twitter_username && `https://twitter.com/${twitter_username}`,
      icon: <SocialIcon network="twitter" style={{width: iconSize, height: iconSize}} />,
    },
    instagram: {
      url: instagram_username && `https://instagram.com/${instagram_username}`,
      icon: <SocialIcon network="instagram" style={{width: iconSize, height: iconSize}} />,
    },
  };


  return (
    <>
      <Banner height={height}>
        <img src={banner_image_url} alt={`${name} banner cover`} />
      </Banner>
      <div className={isMd ? "flex-v flex-center" : "flex-h"}>
        <LogoWrapper
          to={`/tokens/${slug}`}
          className="flex-v flex-center flex-align-center mauto mr15"
        >
          {image_url ? (
            <Avatar src={image_url} size={110} />
          ) : (
            "--"
          )}
        </LogoWrapper>
        <div className={isMd ? "text-center mt10" : "flex-1 mt10"}>
          <Text bold as="h1" size="xl">{title || name}</Text>
        </div>
        <div className={isMd ? "flex-h flex-center mt10" : "flex-h flex-start mt10"}>
          {Object.keys(linkKeys).map((key) => {
            const link = linkKeys[key];
            if (!link.url) return false;
            const {
              icon,
              url,
            } = link;
            return (
              <div className="mr15 h25" key={key}>
                <a className="inline-block fs11" target="_blank" href={url} rel="noopener noreferrer">
                  {icon}
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

const Banner = styled.div`
  height: ${props => props.height || "250"}px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;
const LogoWrapper = styled(LangLink)`
  margin-left: 15px;
  margin-top: -57px;
  background: white;
  border-radius: 114px;
  width: 114px;
  height: 114px;
  z-index: 10;
  position: relative;
  align-self: center;
`;


import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Avatar} from "antd";
import CryptoIcon from "react-crypto-icons";

import TableHorizontal from "../components/TableHorizontal";
import {LangLink} from "../components/Language";
import Text from "../components/Text";
import Loading from "../components/Loading";

import {msg} from "../messages";
import * as TokenActions from "../actions/TokenActions";
import {itemRender} from "../components/Pagination";

import {
  redGreen,
  formatNumber,
} from "../lib/helpers";

export default ({
  onChange,
  page = 1,
  limit = 100,
  filters,
  ...props
}) => {
  const [isLoading, setLoading] = useState();
  const dispatch = useDispatch();
  const tokenList = useSelector(state => {
    const list = state.token.getIn(["getTokenList", Number(page)]);
    if (list) {
      return list.toIndexedSeq()
        .toArray()
        .slice(0, limit)
    }
    return [];
  });

  const pagination = useSelector(state => state.token.get("tokensPagination") || {});

  const loadData = async () => {
    setLoading(true);
    await dispatch(TokenActions.getTokenList({
      page,
      limit,
      filters,
    }));
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [page, limit, filters]);

  const columns = [{
    title: msg("tokens"),
    dataIndex: "slug",
    key: "slug",
    render: (slug, {
      name,
      image_url,
    }) => (
      <div className="flex-h">
        <div>
          <LangLink to={`/tokens/${slug}`}>
            <Avatar src={image_url} size={40} />
          </LangLink>
        </div>
        <div className="ml15 flex-1 flex-v flex-center">
          <Text
            size="m"
            as={LangLink}
            to={`/tokens/${slug}`}
          >
            {name}
          </Text>
        </div>
      </div>
    ),
  }, {
    title: msg("change_24h"),
    dataIndex: "stats",
    key: "stats",
    render: ({
      oneDayChange,
    } = {}) => (
      <div>
        <Text color={redGreen(oneDayChange)}>{formatNumber(oneDayChange, 2)} %</Text>
      </div>
    ),
  }, {
    title: msg("change_7d"),
    dataIndex: "stats",
    key: "stats",
    render: ({
      sevenDayChange,
    } = {}) => (
      <div>
        <Text color={redGreen(sevenDayChange)}>{formatNumber(sevenDayChange, 2)} %</Text>
      </div>
    ),
  }, {
    title: msg("total_volume"),
    dataIndex: "total_volume",
    key: "total_volume",
    render: (total_volume) => (
      <div className="flex-h">
        {!!total_volume && <div><CryptoIcon name="eth" size={20} /></div>}
        <div className="ml5 flex-v flex-center">
          <Text>{total_volume ? formatNumber(total_volume, 2) : "-"}</Text>
        </div>
      </div>
    ),
  }, {
    title: msg("one_day_volume"),
    dataIndex: "one_day_volume",
    key: "one_day_volume",
    render: (one_day_volume) => (
      <div className="flex-h">
        {!!one_day_volume && <div><CryptoIcon name="eth" size={20} /></div>}
        <div className="ml5 flex-v flex-center">
          <Text>{one_day_volume ? formatNumber(one_day_volume, 2) : "-"}</Text>
        </div>
      </div>
    ),
  }, {
    title: msg("floor_price"),
    dataIndex: "floor_price",
    key: "floor_price",
    render: (floor_price) => (
      <div className="flex-h">
        {!!floor_price && <div><CryptoIcon name="eth" size={20} /></div>}
        <div className="ml5 flex-v flex-center">
          <Text>{floor_price ? formatNumber(floor_price, 4) : "-"}</Text>
        </div>
      </div>
    ),
  }];

  if (isLoading && !tokenList.length) {
    return <Loading size={30} />

  };

  return (
    <TableHorizontal
      //loading={isLoading}
      pagination={{
        pageSize: Number(limit),
        total: Number(pagination.total),
        current: Number(page),
        hideOnSinglePage: true,
        position: ["bottomCenter"],
        showSizeChanger: false,
        itemRender,
      }}
      dataSource={tokenList}
      rowKey="id"
      columns={columns}
      {...props}
    />
  );
};

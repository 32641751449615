import React from 'react';
import {Row, Col} from "antd";
import styled from "styled-components";
import {useDispatch} from "react-redux";

//import {history} from "../lib/configureStore";
import { Content, Wrapper, GreenBox } from '../components/Layout';
import Text from "../components/Text";

import {getQuery, media} from "../lib/helpers";
import TableTokenList from "../components/TableTokenList";
import * as WsActions from "../actions/WsActions";

export default ({
  routes,
}) => {
  const dispatch = useDispatch();
  const {
    page,
    limit = 80,
  } = getQuery()

  React.useEffect(() => {
    dispatch(WsActions.init("public"));
  }, []);

  return (
    <Content>
      <Wrapper className="mt30">
        <TableTokenList
          page={page}
          limit={limit}
        />
      </Wrapper>
    </Content>
  );
}

import styled, {keyframes} from "styled-components";

import {media} from "../lib/helpers";

export default () => {
  return (
    <Wrapper className="">
      <Peace>rare</Peace>
      <Aliens>rave</Aliens>
    </Wrapper>
  );
};


const Wrapper = styled.div`
  font-weight: 800;
  line-height: ${props => props.theme.headerHeight};
  color: white;
  font-size: 28px;
  ${media.xs`
    font-size: 25px; 
  `}
`;

const Peace = styled.span`
  letter-spacing: 1.5;
`;

const Aliens = styled.span`
  width: 100%;
  color: #48c248;
`;

import React, {useEffect, useState} from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import {useSelector, useDispatch} from "react-redux";
import {Tag, Avatar, Row, Col, Progress} from "antd";
import {SocialIcon} from "react-social-icons";
import {
  GlobalOutlined,
} from '@ant-design/icons';
import useDeepCompareEffect from 'use-deep-compare-effect'

import Text from "../components/Text";
import {Wrapper, Content} from "../components/Layout";
import Loading from "../components/Loading";

import {isMd as mdSelector} from "../selectors/master";
import EtherscanDark from "../assets/etherscan-dark.svg";
import AssetsList from "../components/AssetsList";
import * as AssetActions from "../actions/AssetActions";
import {getQuery, getAssetImage} from "../lib/helpers";
import TokenBanner from "../components/TokenBanner";

export default ({
  match: {
    params: {
      slug,
      uri_param,
    },
  },
}) => {
  const dispatch = useDispatch();
  const isMd = useSelector(mdSelector);
  const asset = useSelector((state) => state.asset.getIn(["getAsset", `${slug}_${uri_param}`]));
  const [loading, setLoading] = useState();
  const assetsKey = slug;

  const loadData = async () => {
    await dispatch(AssetActions.getAsset({slug, uri_param}));
  };

  console.log(asset);
  useEffect(() => {
    loadData();
  }, []);

  if (!asset) {
    return (
      <div className="mt100">
        <Loading />
      </div>
    );
  }

  const {
    Token,
    TokenId,
    traits,
    score,
    allTraits,
  } = asset;

  const title = `${Token.name} #${uri_param}`;

  return (
    <Wrapper type="fluid">
      <Helmet title={title} />
      <TokenBanner token={Token} height={100} title={title} />
      <Content>
        <Row gutter="20" className="mt30">
          <Col xs={24} sm={24} md={16} className="mb10">
            <img className="fill" src={getAssetImage(Token.address, asset, "800x0")} alt={`${Token.name} ${uri_param}`} />
          </Col>
          <Col xs={24} sm={24} md={8}>
            <div className="flex-h flex-center">
              <Text size="m" className="flex-1">RARITY SCORE</Text>
              <Text size="xl">{score}</Text>
            </div>
            <div className="mb10">
              <Progress percent={score} showInfo={false} />
            </div>

            {traits.attributes.map((attr) => (
              <Attribute>
                <div className="flex-h">
                  <Text size="xxs" bold className="flex-1 flex-v flex-center">
                    {attr.trait_type.toUpperCase()}
                  </Text>
                  <Text>
                    {attr.value}
                    <Tag className="mr0 ml10">
                      <Text size="xxs" color="black">{allTraits[attr.trait_type][attr.value]}</Text>
                    </Tag>
                  </Text>
                </div>
                <Row>
                  <Col xs={19}>
                    <Progress percent={attr.score} size="small" showInfo={false} />
                  </Col>
                  <Col xs={5} className="text-right flex-v flex-center">
                    <Text size="xxs">{attr.score.toFixed(4)}</Text>
                  </Col>
                </Row>
              </Attribute>
            ))}
          </Col>
        </Row>
      </Content>
    </Wrapper>
  );
};

const Attribute = styled.div`
  margin-bottom: 10px;
  border-radius: 5px;
  background: #333;
  padding: 10px;
`;
